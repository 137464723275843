import { Stack, Button, Alert } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { filter, includes, keyBy, keys, map, pick, some } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { ordersApi, requisitesApi } from 'api';
import { requisitesGroupApi } from 'api/requisites-groups.api';
import {
  BankSelect,
  DataWrapper,
  FormikNumericField,
  PaymentTypeSelect,
  FormikSelect,
  FormikRadioGroup,
  RequisitesStatusLabel,
  FormControls,
  FormActions,
  FormikTextField,
  RequisitesBlockedStatusLabel,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import {
  FiatCurrencyCode,
  FormFieldType,
  QueryKey,
  RequisitesStatus,
} from 'enums';
import { FormFields } from 'features/form-builder';
import { useCurrencies, useMutation, useUser, useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FormField, Requisites } from 'types';
import { formUtils, validationUtils } from 'utils';

import { buildValidator } from './validator';

type Props = {
  id: string;
};

type Values = Omit<
  Requisites,
  | 'id'
  | 'createdAt'
  | 'limitActiveOrders'
  | 'limitCountPerHour'
  | 'limitCountPerDay'
  | 'limitCountPerMonth'
  | 'limitSumPerHour'
  | 'limitSumPerDay'
  | 'limitSumPerMonth'
  | 'automationApiKey'
  | 'verificationStatus'
  | 'verifiedAt'
  | 'orderAutomationType'
> &
  Partial<
    Pick<
      Requisites,
      | 'limitActiveOrders'
      | 'limitCountPerHour'
      | 'limitCountPerDay'
      | 'limitCountPerMonth'
      | 'limitSumPerHour'
      | 'limitSumPerDay'
      | 'limitSumPerMonth'
    >
  >;

const FIELDS_TO_KEEP_ON_CURRENCY_CHANGE: (keyof Values)[] = [
  'limitActiveOrders',
  'limitCountPerHour',
  'limitCountPerDay',
  'limitCountPerMonth',
  'limitSumPerHour',
  'limitSumPerDay',
  'limitSumPerMonth',
  'completedOrdersInMinutes',
  'status',
  'name',
  'groupId',
];

export const RequisitesDetailsForm: React.FC<Props> = ({ id }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'pages.requisites.details',
  });
  const { t: tFields } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_details.fields',
  });

  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const navigate = useNavigate();

  const { role, isTrader, isAdminOrTechOperator, isAdmin, isTechOperator } =
    useUser();

  const { fiatCurrenciesOptions, getFiatCurrencyByCode } = useCurrencies();

  const { banks, paymentTypes, tradeMethods } = useUserContext();

  const [ordersExistsByRequisites, setOrderExistsByRequisites] =
    useState(false);

  const rubFiatCurrencyId = useMemo(
    () => getFiatCurrencyByCode(FiatCurrencyCode.RUB)?.id,
    [getFiatCurrencyByCode],
  );

  const paymentTypeFields = useMemo(
    () => keyBy(paymentTypes, 'id'),
    [paymentTypes],
  );

  const [currentFields, setCurrentFields] = useState<FormField[] | null>(null);

  const requisitesSchema = useMemo(
    () => buildValidator(currentFields),
    [currentFields],
  );

  const isNew = useMemo(() => id === NEW_ID, [id]);

  const initialState = useMemo(
    () => ({
      cardInfo: '',
      phone: '',
      email: '',
      cardholder: '',
      status: RequisitesStatus.Inactive,
      limitActiveOrders: 100,
      limitCountPerHour: 1000,
      limitCountPerDay: 10000,
      limitCountPerMonth: 100000,
      limitSumPerHour: 10000000,
      limitSumPerDay: 100000000,
      limitSumPerMonth: 1000000000,
      completedOrdersInMinutes: 0,
      paymentTypeId: '',
      bankId: '',
      fiatCurrencyId: '',
      swiftBic: '',
      bic: '',
      idCard: '',
      accountNumber: '',
      name: '',
      expirationDate: '',
      groupId: '',
      accountLastDigits: '',
      taxId: '',
    }),
    [],
  );

  const normalizeFieldsConfig = useMemo(
    (): Partial<{
      [key in FormFieldType]: { requiredForCurrencies: string[] };
    }> => ({
      [FormFieldType.TaxId]: {
        requiredForCurrencies: rubFiatCurrencyId ? [rubFiatCurrencyId] : [],
      },
    }),
    [rubFiatCurrencyId],
  );

  const isTradeMethodNotSupported = useCallback(
    (values: Values) =>
      values.fiatCurrencyId &&
      values.paymentTypeId &&
      values.bankId &&
      !some(tradeMethods, {
        fiatCurrencyId: values.fiatCurrencyId,
        paymentTypeId: values.paymentTypeId,
        bankId: values.bankId,
      }),
    [tradeMethods],
  );

  const [initialValues, setInitialValues] = useState<Values>(initialState);

  const normalizeCurrentFieldsToSet = useCallback(
    (fields: FormField[] | null, values: Values) => {
      if (!fields) {
        return fields;
      }
      return map(fields, (field) => {
        const config = normalizeFieldsConfig[field.type];
        const resultField = { ...field };
        if (config) {
          if (includes(config.requiredForCurrencies, values.fiatCurrencyId)) {
            resultField.required = true;
          }
        }
        return resultField;
      });
    },
    [normalizeFieldsConfig],
  );

  const queryResult = useQuery(
    [QueryKey.Requisites, id],
    () => requisitesApi.findOne(id as string),
    {
      enabled: !isNew,
      onSuccess: (data) => {
        const pickedData = pick(data, keys(initialState)) as Values;
        setInitialValues(pickedData);
        setCurrentFields(
          data.paymentTypeId
            ? normalizeCurrentFieldsToSet(
                paymentTypeFields[data.paymentTypeId].fields || null,
                pickedData,
              )
            : null,
        );
      },
    },
  );

  const { data: requisitesGroupsOptions } = useQuery(
    QueryKey.RequisitesGroups,
    () =>
      isAdminOrTechOperator && queryResult.data?.traderId
        ? requisitesGroupApi.getAllTraderAsRole(role)(
            queryResult.data?.traderId,
          )
        : requisitesGroupApi.getAllAsRole(role)(),
    { select: formUtils.getOptions, enabled: queryResult.isSuccess },
  );

  const queryResultOrderExistsByRequisites = useQuery(
    [QueryKey.OrderExistsByRequisites, id],
    () => ordersApi.getOrderExistsByRequisitesAsRole(role)(id as string),
    {
      enabled: !isNew,
      onSuccess: (data) => {
        setOrderExistsByRequisites(data.exists);
      },
    },
  );

  const isBlocked = useMemo(
    () => queryResult.data?.status === RequisitesStatus.Blocked,
    [queryResult],
  );

  const backUrl = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.REQUISITES;
    }

    if (isAdmin) {
      return ROUTE_PATH.ADMIN.REQUISITES;
    }

    if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.REQUISITES;
    }

    return '';
  }, [isTrader, isAdmin, isTechOperator]);

  const canChangeRequisitesPayment = useMemo(
    () =>
      isNew ||
      (!queryResultOrderExistsByRequisites.isLoading &&
        !ordersExistsByRequisites),
    [
      isNew,
      ordersExistsByRequisites,
      queryResultOrderExistsByRequisites.isLoading,
    ],
  );

  const { mutate: createRequisites } = useMutation<
    Requisites,
    AxiosError,
    Partial<Requisites>
  >(requisitesApi.create);

  const { mutate: updateRequisites } = useMutation<
    Requisites,
    AxiosError,
    { id: string; requisites: Partial<Values> }
  >(requisitesApi.update);

  const handleSubmit = useCallback(
    (requisites: Values, formikHelpers: FormikHelpers<Values>) => {
      const options = {
        onSuccess: () => navigate(backUrl),
        onError: (error: AxiosError) =>
          formikHelpers.setErrors(validationUtils.getFormErrors(error)),
        onSettled: () => formikHelpers.setSubmitting(false),
      };

      formikHelpers.setSubmitting(true);

      // Select only fields that are in the current schema
      const requisiteData = pick(requisites, keys(requisitesSchema.fields));

      if (isNew) {
        createRequisites(requisiteData, options);
      } else {
        updateRequisites({ id, requisites: requisiteData }, options);
      }
    },
    [
      isNew,
      backUrl,
      navigate,
      createRequisites,
      updateRequisites,
      id,
      requisitesSchema.fields,
    ],
  );

  const handleCancel = useCallback(
    () => navigate(backUrl),
    [backUrl, navigate],
  );

  const requisitesOptions = useMemo(
    () =>
      map(
        [RequisitesStatus.Active, RequisitesStatus.Inactive],
        (status: RequisitesStatus.Active | RequisitesStatus.Inactive) => ({
          value: status,
          label: <RequisitesStatusLabel status={status} />,
        }),
      ),
    [],
  );

  const getAvailablePaymentTypes = useCallback(
    (values: Values) => {
      const availableTradeMethods = filter(
        tradeMethods,
        (tradeMethod) => tradeMethod.fiatCurrencyId === values.fiatCurrencyId,
      );
      const availablePaymentTypesIds = new Set(
        map(availableTradeMethods, (tradeMethod) => tradeMethod.paymentTypeId),
      );
      const availablePaymentTypes = filter(paymentTypes, (paymentType) =>
        availablePaymentTypesIds.has(paymentType.id),
      );
      return availablePaymentTypes;
    },
    [paymentTypes, tradeMethods],
  );

  const getAvailableBanks = useCallback(
    (values: Values) => {
      const availableTradeMethods = filter(
        tradeMethods,
        (tradeMethod) =>
          tradeMethod.fiatCurrencyId === values.fiatCurrencyId &&
          tradeMethod.paymentTypeId === values.paymentTypeId,
      );
      const availableBanksIds = new Set(
        map(availableTradeMethods, (tradeMethod) => tradeMethod.bankId),
      );
      const availableBanks = filter(banks, (bank) =>
        availableBanksIds.has(bank.id),
      );
      return availableBanks;
    },
    [banks, tradeMethods],
  );

  const handleOnChangePaymentType = useCallback(
    (paymentTypeId: string, formik: FormikProps<Values>) => {
      formik.setFieldValue('bankId', '');
      formik.setTouched({ bankId: false });
      setCurrentFields(
        normalizeCurrentFieldsToSet(
          paymentTypeFields[paymentTypeId]?.fields || null,
          formik.values,
        ),
      );
      setTimeout(() => formik.validateForm()); // Dirty hack to validate form after fields change, because Formik doesn't do it automatically
    },
    [paymentTypeFields, normalizeCurrentFieldsToSet],
  );

  // Reset form to initial state when fiat currency changes
  const handleOnChangeFiatCurrency = useCallback(
    (fiatCurrencyId: string, formik: FormikProps<Values>) => {
      const newValues = {
        ...initialState,
        ...pick(formik.values, FIELDS_TO_KEEP_ON_CURRENCY_CHANGE),
        fiatCurrencyId,
      };
      setInitialValues(newValues);
      setCurrentFields(null);
    },
    [initialState],
  );

  return (
    <DataWrapper queryResult={!isNew ? queryResult : undefined}>
      <div className="tw-max-w-md">
        {!canChangeRequisitesPayment && (
          <Alert severity="warning" className="tw-mb-4">
            {t('requisites_in_use')}
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={requisitesSchema}
          onSubmit={handleSubmit}
          handleCancel={handleCancel}
          validateOnMount
          validateOnChange
          enableReinitialize
        >
          {(formik: FormikProps<Values>) => {
            const availableBanks = getAvailableBanks(formik.values);
            const showBanks =
              !!formik.values.paymentTypeId && !!formik.values.fiatCurrencyId;
            const showPaymentType = !!formik.values.fiatCurrencyId;

            return (
              <Form>
                {isTradeMethodNotSupported(formik.values) && (
                  <Alert severity="warning">
                    {tCommon(
                      'features.requisites.error.trade_method_unavailable',
                    )}
                  </Alert>
                )}
                <FormControls>
                  {!isBlocked && (
                    <FormikRadioGroup
                      label={tFields('status')}
                      name="status"
                      options={requisitesOptions}
                      disabled={isBlocked}
                    />
                  )}

                  {isBlocked && queryResult.data && (
                    <RequisitesBlockedStatusLabel
                      status={queryResult.data.status}
                      statusDetails={queryResult.data.statusDetails}
                      blockedMessage={queryResult.data.blockedMessage}
                      blockedBy={queryResult.data.blockedBy}
                      blockedAt={queryResult.data.blockedAt}
                    />
                  )}

                  <FormikTextField name="name" label={tFields('name')} />

                  <FormikSelect
                    label={tFields('group')}
                    name={'groupId'}
                    required
                    options={requisitesGroupsOptions || []}
                    noneOption
                  />

                  <FormikSelect
                    label={tFields('currency')}
                    name="fiatCurrencyId"
                    options={fiatCurrenciesOptions}
                    disabled={!canChangeRequisitesPayment}
                    required
                    noneOption
                    onChange={(value) =>
                      handleOnChangeFiatCurrency(value, formik)
                    }
                  />

                  {showPaymentType && (
                    <PaymentTypeSelect
                      name="paymentTypeId"
                      paymentTypes={getAvailablePaymentTypes(formik.values)}
                      disabled={!canChangeRequisitesPayment}
                      required
                      noneOption
                      onChange={(value) => {
                        handleOnChangePaymentType(value, formik);
                      }}
                    />
                  )}

                  {showBanks && (
                    <Fragment>
                      <BankSelect
                        name="bankId"
                        disabled={!canChangeRequisitesPayment}
                        banks={availableBanks}
                        required
                        noneOption
                      />
                    </Fragment>
                  )}
                  {currentFields && (
                    <FormFields
                      fields={currentFields}
                      disabled={!canChangeRequisitesPayment}
                    />
                  )}

                  <Stack direction="row" spacing={3}>
                    <FormikNumericField
                      name="limitCountPerHour"
                      label={tFields('limit_count_per_hour')}
                      decimalScale={0}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                    <FormikNumericField
                      name="limitCountPerDay"
                      label={tFields('limit_count_per_day')}
                      decimalScale={0}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                    <FormikNumericField
                      label={tFields('limit_count_per_month')}
                      name="limitCountPerMonth"
                      decimalScale={0}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                  </Stack>

                  <Stack direction="row" spacing={3}>
                    <FormikNumericField
                      name="limitSumPerHour"
                      label={tFields('limit_sum_per_hour')}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                    <FormikNumericField
                      name="limitSumPerDay"
                      label={tFields('limit_sum_per_day')}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                    <FormikNumericField
                      name="limitSumPerMonth"
                      label={tFields('limit_sum_per_month')}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                  </Stack>
                  <Stack spacing={3}>
                    <FormikNumericField
                      name="limitActiveOrders"
                      label={tFields('limit_active_orders')}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                    <FormikNumericField
                      name="completedOrdersInMinutes"
                      label={tFields('completed_orders_in_minutes')}
                      helperText={tFields(
                        'completed_orders_in_minutes_description',
                      )}
                      allowNegative={false}
                      required
                      fullWidth
                    />
                  </Stack>
                </FormControls>
                <FormActions>
                  <Button variant="outlined" onClick={handleCancel}>
                    {tCommon('buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting || !formik.isValid}
                    onClick={formik.submitForm}
                  >
                    {tCommon('buttons.save')}
                  </Button>
                </FormActions>
              </Form>
            );
          }}
        </Formik>
      </div>
    </DataWrapper>
  );
};
